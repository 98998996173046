/**
 * Created by mac on 5/6/17.
 */

cleverapps.styles.COLORS = cleverapps.overrideColors(cleverapps.styles.COLORS, {
    PATH_COLOR: new cc.Color(163, 211, 200, 255),
    PATH_HARD_COLOR: new cc.Color(163, 211, 200, 255),
    LETTER: new cc.Color(80, 60, 35, 255),
    COLOR_VICTORY: new cc.Color(240, 73, 0, 255),
    COLOR_LOADING: new cc.Color(50, 50, 255, 255),
    PLAYER_ALARM_COLOR: new cc.Color(252, 148, 37, 255)
});

cleverapps.styles.DECORATORS = cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    REWARDS_TEXT_STROKE: {
        color: new cc.Color(111, 55, 14, 255),
        size: 2
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    CONGRATE_TEXT: {
        size: 100,
        color: cleverapps.styles.COLORS.CONGRATS_BLUE,
        shadow: cleverapps.styles.DECORATORS.WHITE_TEXT_SHADOW
    },

    LETTERS_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.LETTER
    },

    CLOVERS_TEXT: {
        size: 50,
        name: "nostroke",
        color: cleverapps.styles.COLORS.COLOR_BROWN
    },

    REWARDS_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.COLOR_BROWN
    }
});

cleverapps.overrideStyles(cleverapps.styles.HintButtonBooster, {
    width: 220,
    height: 110,
    zOrder: 10,

    position: [
        { x: { align: "right", dx: 0 }, y: { align: "bottom", dy: 510 } },
        { x: { align: "right", dx: -10 }, y: { align: "top", dy: -710 } },
        { x: { align: "right", dx: -10 }, y: { align: "top", dy: -710 } }
    ],
    
    phone: {
        scale: 1.0
    }
});

cleverapps.overrideStyles(cleverapps.styles.OpenLetterButtonBooster, {
    width: 220,
    height: 110,
    zOrder: 10,

    position: [
        { x: { align: "right", dx: 0 }, y: { align: "bottom", dy: 360 } },
        { x: { align: "right", dx: -10 }, y: { align: "top", dy: -860 } },
        { x: { align: "right", dx: -10 }, y: { align: "top", dy: -860 } }
    ],

    content: {
        direction: cleverapps.UI.HORIZONTAL,
        layout: {
            x: { align: "center", dx: 3 },
            y: { align: "center", dy: 0 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.BoosterAmountView, {
    background: {
        scale: 1
    },
    amount: {
        scale: 0.7
    }
});

cleverapps.overrideStyles(cleverapps.styles.BoardView, {
    padding: {
        bottom: -20
    },

    BgTop: {
        x: { align: "center" },
        y: { align: "top" }
    },

    Background: {
        padding: {
            top: 150,
            bottom: 30
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.GameScene, {
    sound: {
        delay: 1.7
    }
});

cleverapps.overrideStyles(cleverapps.styles.CloversView, {
    width: 200,
    height: 70,

    text: {
        y: { align: "center" },
        x: { align: "center", dx: 40 }
    },

    icon: {
        width: 120,

        x: { align: "left", dx: 5 },
        y: { align: "center", dy: 10 }
    },

    position: [
        {
            x: { align: "left", dx: 10 },
            y: { align: "top", dy: -740 }
        },
        {
            x: { align: "left", dx: 10 },
            y: { align: "top", dy: -740 }
        },
        {
            x: { align: "left", dx: 10 },
            y: { align: "top", dy: -740 }
        }
    ]
});

cleverapps.overrideStyles(cleverapps.styles.CurrentView, {
    statusIcon: {
        offsetX: -20
    },
    content: {
        y: -10
    },
    bg: {
        width: 500,
        height: 110,
        resize: true,
        padding: {
            x: 140
        },
        y: { align: "center", dy: 5 }
    },
    extraAnimation: {
        scale: 0.5
    },
    hide: {
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: -420 }
    },
    position: {
        y: { align: "top", dy: -620 }
    },
    zOrder: -3
});

cleverapps.overrideStyles(cleverapps.styles.KeypadView, {
    radius: {
        default: 190,
        "3": 160,
        "4": 170,
        "5": 185,
        "6": 190
    },

    controls: {
        width: 110,
        height: 110
    },

    bg: {
        diameter: 140
    }
});

cleverapps.overrideStyles(cleverapps.styles.KeypadLetterView, {
    noBg: true
});

cleverapps.overrideStyles(cleverapps.styles.PackWindow, {
    Title: {
        fitTo: {
            width: 350,
            height: 70
        },
        dx: 20,
        dy: 265,
        delay: 0.7
    },

    discountLabel: {
        dx: 265,
        dy: 310,
        delay: 1,
        duration: 0.675,
        fitTo: {
            width: 150
        }
    },

    animation: {
        "default": {
            idleDelay: -1.3,
            dx: 0,
            dy: 0
        },

        "starterPack0": {
            idleAnimation: "idle_800",
            introAnimation: "open_800"
        },

        "starterPack": {
            idleAnimation: "idle_2600",
            introAnimation: "open_2600"
        },

        "starterPack2": {
            idleAnimation: "idle_17000",
            introAnimation: "open_17000"
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.CryptexView, {
    rewardView: {
        offsetY: -150,
        offsetX: 75
    }
});

cleverapps.overrideStyles(cleverapps.styles.LoadingAnimation, {
    text: false
});

cleverapps.overrideStyles(cleverapps.styles.ConfirmExitWindow, {
    animation: {
        width: 400,
        height: 400,

        x: { align: "center" },
        y: { align: "center", dy: -70 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.LevelView, {
    clickSize: {
        width: 100,
        height: 80
    },

    LevelId: {
        y: 40
    },

    dy: 0
});

cleverapps.overrideStyles(cleverapps.styles.WeeklyCupView, {
    margin: 5,

    padding: {
        top: -50,
        bottom: 40
    },

    helpButton: {
        x: { align: "right", dx: -5 },
        y: { align: "top", dy: -10 }
    },

    title: {
        text: {
            width: 520,
            x: { align: "center", dx: 1 }
        }
    },

    table: [{ height: 800 }, { height: 460 }, { height: 460 }]
});

cleverapps.overrideStyles(cleverapps.styles.RestoreProgressButton, {
    size: [
        { width: 180, height: 90 },
        { width: 300, height: 110 },
        { width: 300, height: 110 }
    ]
});